import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Tab,
  Tabs,
  Box,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Modal,
  TextField,
  LinearProgress,
  useMediaQuery,
} from '@mui/material';
import { Email } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../context/AuthContext';
import { useQuery, useMutation, gql } from '@apollo/client';

export const ORGANIZER_PROFILE_INFO_QUERY = gql`
  query UserProfileInfo {
  userProfileInfo {
    email
    firstName
    preferredName
    profilePicUrl
    lastName
    hasAccount
  }
}
`;


const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser($input: iRegisterUser!) {
    registerUser(input: $input)
  }
`;


export const GET_USER_EVENTS = gql`
  query GetUserEvents {
    getUserEvents {
      requiresId
      startDate
      endDate
      type
      venue
      name
      eventMedia {
        mediaUrl
      }
      description
      qrData
      id
    }
  }
`;


const colors = {
  primary: '#020420',
  secondary: '#ffffff',
  textPrimary: '#333333',
};

const HomePage = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    repeatPassword: '',
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fetch profile info and handle errors gracefully
  const { data, loading: profileLoading, error: profileError } = useQuery(ORGANIZER_PROFILE_INFO_QUERY, {
    onError: (err) => {
      console.error("Error fetching profile data:", err);
    },
  });

  const { data: eventsData, loading: eventsLoading } = useQuery(GET_USER_EVENTS);


  const [registerUser] = useMutation(REGISTER_USER_MUTATION);

  const handleLogout = async () => {
    await logout();
    navigate('/sign-in');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenModal = () => {
    setShowModal(true);
    setErrorMessage(null); // Clear any previous error message
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePasswordDataChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({ ...prev, [name]: value }));
    if (name === 'newPassword') calculatePasswordStrength(value);
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 8) strength += 40;
    if (/[A-Z]/.test(password)) strength += 20;
    if (/\d/.test(password)) strength += 20;
    if (/[^A-Za-z0-9]/.test(password)) strength += 20;
    setPasswordStrength(strength);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.repeatPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      await registerUser({
        variables: {
          input: {
            password: passwordData.newPassword,
          },
        },
      });
      setShowModal(false); // Close modal on success
      setErrorMessage(null); // Clear any error message
      setPasswordData({ newPassword: '', repeatPassword: '' }); // Reset form fields
      alert("Password updated successfully!"); // Optional feedback
    } catch (error) {
      console.error("Error updating password:", error);
      setErrorMessage("Failed to update password. Please try again.");
    }
  };

  // if (loading) return <Typography>Loading...</Typography>;
  if (profileLoading || eventsLoading) return <Typography>Loading...</Typography>;

  // if (profileError || eventsError) {
  //   return (
  //     <Typography color="error">
  //       Failed to load data. Please refresh the page or contact support.
  //     </Typography>
  //   );
  // }

 
  // Use fallback data if there's an error
// Use fallback data if there's an error
const profileData = profileError
? {
    firstName: 'John',
    lastName: 'Doe',
    preferredName: 'John Doe',
    profilePicUrl: 'https://placehold.co/150',
    email: 'notavailable@example.com',
  }
: data?.userProfileInfo;

  
const events = eventsData?.getUserEvents || []; // Replace mock events with API response

const displayName = profileData.preferredName || `${profileData.firstName} ${profileData.lastName}`;
const profilePicUrl = profileData.profilePicUrl;
const email = profileData.email;


  // Mock events data for the events tab
  // const mockEvents = [
  //   {
  //     id: 1,
  //     name: 'Tech Networking Event',
  //     venue: 'Downtown Conference Center',
  //     type: 'Networking',
  //     startDate: '2024-11-20T18:00:00',
  //     description: 'Join us for a night of networking with top tech professionals in the industry.',
  //   },
  //   {
  //     id: 2,
  //     name: 'AI Workshop',
  //     venue: 'Tech Hub Auditorium',
  //     type: 'Workshop',
  //     startDate: '2024-12-05T10:00:00',
  //     description: 'A hands-on workshop exploring the latest in AI technology.',
  //   },
  // ];

  return (
    <Box sx={{ backgroundColor: colors.background, minHeight: '100vh', color: colors.textPrimary }}>
      <AppBar position="static" sx={{ backgroundColor: colors.primary, mb: 2 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ color: colors.secondary, fontSize: isMobile ? '1.2rem' : '1.5rem' }}>
            Dashboard
          </Typography>
          <Button onClick={handleLogout} sx={{ color: colors.secondary, fontWeight: 'bold', justifyContent: 'right' }}>
            Log Out
          </Button>
        </Toolbar>
      </AppBar>

      <Container maxWidth="md" sx={{ paddingBottom: isMobile ? 3 : 5 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: colors.primary },
            '& .MuiTab-root': {
              fontWeight: 'bold',
              color: colors.textPrimary,
              fontSize: isMobile ? '0.875rem' : '1rem',
            },
            '& .Mui-selected': { color: colors.primary },
          }}
        >
          <Tab label="Events" />
          <Tab label="Profile" />
        </Tabs>

        {activeTab === 0 && (
        <Box sx={{ mt: 2 }}>
        <Grid container spacing={3}>
          {events.map((event) => (
            <Grid item xs={12} sm={12} md={12} key={event.id}>
              <Card
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="150"
                  image={event.eventMedia?.[0]?.mediaUrl || 'https://placehold.co/400x200'}
                  alt="Event Image"
                  sx={{
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                  }}
                />
                <CardContent sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.primary, mb: 1 }}>
                    {event.name}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1, color: '#666' }}>
                    <strong>Venue:</strong> {event.venue}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1, color: '#666' }}>
                    <strong>Type:</strong> {event.type}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1, color: '#666' }}>
                    <strong>Start Date:</strong> {new Date(event.startDate).toLocaleString()}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1, color: '#888' }}>
                    {event.description}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colors.primary,
                      color: colors.secondary,
                      mt: 2,
                      width: '100%',
                      textTransform: 'none',
                    }}
                    component={RouterLink}
                    to={`/events/${event.id}`}
                  >
                    View Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      )}


        {activeTab === 1 && (
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '100%',
                maxWidth: 500,
                backgroundColor: colors.secondary,
                color: colors.textPrimary,
                borderRadius: '8px',
                boxShadow: 1,
                p: 3,
                textAlign: 'center',
                mx: 'auto',
              }}
            >
              <Avatar
                alt={displayName}
                src={profilePicUrl}
                sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
              />
              <Typography variant="h6" sx={{ color: colors.primary, fontWeight: 'bold', mb: 2 }}>
                {displayName}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <Email sx={{ color: colors.primary, mr: 1 }} />
                {email}
              </Typography>
              {/* <Typography variant="body2" sx={{ mb: 1 }}>
                <Work sx={{ color: colors.primary, mr: 1 }} />
                Software Engineer
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <LocationOn sx={{ color: colors.primary, mr: 1 }} />
                Toronto, Canada
              </Typography> */}
              {/* <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                Passionate about technology and innovation, with experience in building scalable applications.
                Excited to explore new opportunities in AI and machine learning.
              </Typography> */}
              {!profileData.hasAccount && (
  <Button
    variant="contained"
    onClick={handleOpenModal}
    sx={{ backgroundColor: colors.primary, color: colors.secondary }}
  >
    Set Password
  </Button>
)}

            </Box>
          </Box>
        )}
      </Container>

      <Modal open={showModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            backgroundColor: colors.secondary,
            padding: 3,
            borderRadius: 2,
            boxShadow: 24,
            color: colors.textPrimary,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: colors.primary }}>Set Password</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="New Password"
              type="password"
              name="newPassword"
              fullWidth
              value={passwordData.newPassword}
              onChange={handlePasswordDataChange}
              sx={{ mb: 2 }}
              required
            />
            <Box sx={{ width: '100%', mb: 1 }}>
              <LinearProgress
                variant="determinate"
                value={passwordStrength}
                sx={{
                  height: 10,
                  backgroundColor: colors.background,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: passwordStrength > 80 ? 'green' : passwordStrength > 50 ? 'orange' : 'red',
                  },
                }}
              />
            </Box>
            <TextField
              label="Repeat New Password"
              type="password"
              name="repeatPassword"
              fullWidth
              value={passwordData.repeatPassword}
              onChange={handlePasswordDataChange}
              sx={{ mb: 2 }}
              required
            />
            {errorMessage && <Typography color="error" sx={{ mb: 2 }}>{errorMessage}</Typography>}
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, backgroundColor: colors.primary, color: colors.secondary }}
            >
              Update Password
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default HomePage;
