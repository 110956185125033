import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { QRCodeCanvas } from 'qrcode.react'; // Correct import
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './EventPage.css';

export const GET_USER_EVENTS = gql`
  query GetUserEvents {
    getUserEvents {
      id
      requiresId
      startDate
      endDate
      type
      venue
      name
      eventMedia {
        mediaUrl
      }
      description
      qrData
    }
  }
`;

const EventPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_USER_EVENTS);

  if (loading) return <Typography>Loading event details...</Typography>;
  if (error) return <Typography color="error">Error loading event: {error.message}</Typography>;

  const event = data?.getUserEvents?.find((event) => event.id === id);

  if (!event) {
    return <Typography color="error">Event not found.</Typography>;
  }

  // const generateICSFile = (event) => {
  //   const icsContent = `
  // BEGIN:VCALENDAR
  // VERSION:2.0
  // PRODID:-//Your App Name//EN
  // BEGIN:VEVENT
  // UID:${event.id}
  // DTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').split('.')[0]}Z
  // DTSTART:${new Date(event.startDate).toISOString().replace(/[-:]/g, '').split('.')[0]}Z
  // DTEND:${new Date(event.endDate).toISOString().replace(/[-:]/g, '').split('.')[0]}Z
  // SUMMARY:${event.name}
  // DESCRIPTION:${event.description || ''}
  // LOCATION:${event.venue || ''}
  // END:VEVENT
  // END:VCALENDAR
  // `;
  
  //   const blob = new Blob([icsContent], { type: 'text/calendar' });
  //   const url = URL.createObjectURL(blob);
  
  //   // Create a temporary link to trigger the download
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = `${event.name}.ics`;
  //   document.body.appendChild(link);
  //   link.click();
  
  //   // Clean up the URL object and link
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };


  const openInGoogleMapsByName = (venueName) => {
    const encodedVenueName = encodeURIComponent(venueName);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedVenueName}`;
    window.open(googleMapsUrl, '_blank');
  };

  const openInGoogleCalendar = (event) => {
    const baseUrl = 'https://www.google.com/calendar/render';
    const startDate = new Date(event.startDate).toISOString().replace(/[-:]/g, '').split('.')[0];
    const endDate = new Date(event.endDate).toISOString().replace(/[-:]/g, '').split('.')[0];
  
    const params = new URLSearchParams({
      action: 'TEMPLATE',
      text: event.name,
      details: event.description || '',
      location: event.venue || '',
      dates: `${startDate}/${endDate}`,
    });
  
    const googleCalendarUrl = `${baseUrl}?${params.toString()}`;
    window.open(googleCalendarUrl, '_blank');
  };
  
  
  
  return (
    <Box className="event-page" sx={{ padding: 3 }}>
      <Button onClick={() => navigate(-1)} sx={{ mb: 2, color: '#333' }}>
        <FontAwesomeIcon icon={faArrowLeft} />  Back
      </Button>
      <Card sx={{ maxWidth: 600, margin: 'auto', boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
            {event.name}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Type:</strong> {event.type}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            
              <strong>Venue: <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ cursor: 'pointer', color: '#020420' }}
              onClick={() => openInGoogleMapsByName(event.venue)}
              title="Open in Google Maps"
              /> </strong> {event.venue}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Start Date:</strong> {new Date(event.startDate).toLocaleString()}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>End Date:</strong> {new Date(event.endDate).toLocaleString()}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
            {event.description}
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 3 }}>
  <QRCodeCanvas value={event.qrData} size={200} />
  <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#020420',
        color: '#ffffff',
        width: '100%', // Optional: full width button on smaller devices
        maxWidth: '300px', // Limit width on larger screens
        textAlign: 'center', // Ensure text stays centered
      }}
      onClick={() => openInGoogleCalendar(event)}
    >
      Add to Calendar
    </Button>
  </Box>
</Box>

        </CardContent>
      </Card>
    </Box>
    
  );
};

export default EventPage;
